<template>
  <div class="RequestPreviewBody">
    <div class="title">
      Request Body <span class="hint">{{ bodyType | requestBodyType }}</span>
    </div>

    <div class="description" v-if="!bodyType">
      We won't send any data in request body
    </div>

    <div class="body-raw"
         v-if="bodyType === 'raw' || bodyType === 'json'">
      {{ body }}
    </div>

    <div class="body-form" v-if="bodyType === 'form_params'">
      <div class="param"
           v-for="param in body"
           :key="param.name">
        <div class="name">{{ param.name }}</div>
        <div class="value">{{ param.value }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    monitor: {
      required: true,
      type: Object
    }
  },

  computed: {
    body () {
      switch (this.bodyType) {
        case 'raw': return this.monitor.request.body_raw
        case 'json': return this.monitor.request.body_json
        case 'form_params': return this.monitor.request.body_form_params
      }

      return null
    },

    bodyType () {
      return this.monitor.request.body_type
    }
  }
}
</script>

<style lang="scss" scoped>
  .RequestPreviewBody {
    .title {
      font-weight: 400;
      margin-bottom: 4px;
    }

    .description {
      font-size: 14px;
      color: #999ca7;
    }

    .hint {
      font-size: 12px;
      color: #999ca7;
    }

    .body-raw {
      font-size: 14px;
    }

    .param {
      display: flex;
      flex-wrap: wrap;
      padding: 4px 0;
    }

    .name {
      width: 160px;
      font-weight: 400;
      font-size: 14px;
      color: #999ca7;
    }

    .value {
      display: flex;
      align-items: center;
      max-width: 200px;
      font-weight: 700;
      font-size: 11px;
      line-height: 1;
      text-transform: uppercase;
    }
  }
</style>
