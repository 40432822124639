<template>
  <div class="MonitorSettingsRequest">
        <base-dropdown label="Request type"
                       value="HTTP"
                       id = 'typeBox'
                       item-label="title"
                       :searchable="false"
                       track-by="type"
                       v-model="selectedRequestType"
                       :options="requestMethods"
                       @select="$emit('update:request_type',  $event)"
        />

        <div v-if="selectedRequestType.type === 'http'">
          <base-dropdown label="HTTP Method"
                         item-label="title"
                         :searchable="false"
                         track-by="type"
                         v-model="selectedHttpMethod"
                         :options="httpMethods"
                         @select="$emit('update:request_http_method', $event)"
          />

          <request-body v-model="requestBody"  @update="$emit('update:request_body', $event)"/>

          <request-headers v-model="request.headers" @update="$emit('update:request_headers', $event)"/>
        </div>
        <base-textbox v-if="selectedRequestType.type === 'tcp'"
                      label ="Ports"
                      v-model="request.tcp_ports"
                      description="Add the ports to be checked. E.g.: 80,433,200"
                      @input="$emit('update:request_tcp_ports', $event)"
                      />
  </div>
</template>

<script>
import RequestBody from './RequestBody.vue'
import RequestHeaders from './RequestHeaders.vue'
import { cloneObject } from '@/services/utils.js'
import { bus } from '../../../../main'

export default {
  components: {
    RequestBody,
    RequestHeaders
  },

  props: {
    monitor: {
      required: true,
      type: Object
    }
  },

  data () {
    return {
      request: {
        type: '',
        method: '',
        body: '',
        timeout: '',
        headers: [],
        tcp_ports: ''
      },

      httpMethods: [
        {
          type: 'get',
          title: 'GET'
        },
        {
          type: 'post',
          title: 'POST'
        },
        {
          type: 'put',
          title: 'PUT'
        },
        {
          type: 'patch',
          title: 'PATCH'
        },
        {
          type: 'delete',
          title: 'DELETE'
        },
        {
          type: 'head',
          title: 'HEAD'
        },
        {
          type: 'options',
          title: 'OPTIONS'
        }
      ],
      selectedHttpMethod: null,
      selectedRequestType: null,
      requestMethods: [
        {
          type: 'http',
          title: 'HTTP'
        },
        {
          type: 'tcp',
          title: 'TCP'
        },
        {
          type: 'icmp',
          title: 'ICMP'
        }
      ]
    }
  },

  created () {
    this.initRequestType()
    this.initRequestAttributes()
    this.initTcpPorts()
  },

  methods: {
    initRequestAttributes () {
      if (!this.monitor.request.method) {
        this.selectedHttpMethod = {
          type: 'get',
          title: 'GET'
        }
      } else {
        this.selectedHttpMethod = this.httpMethods.find(
          method => method.type === this.monitor.request.method
        )
      }

      this.request = cloneObject(this.monitor.request)

      if (!this.request.headers) {
        this.request.headers = [{
          name: '',
          value: ''
        }]
      }
    },

    initRequestType () {
      this.selectedRequestType = this.requestMethods.find(
        method => method.type === this.monitor.request.type
      )
    },

    initTcpPorts () {
      this.request.tcp_ports = this.monitor.request.tcp_ports
    }
  },

  computed: {
    requestBody: {
      get () {
        return {
          type: this.monitor.request.body_type,
          body: this.monitor.request.body_raw,
          json: this.monitor.request.body_json,
          formParams: this.monitor.request.body_form_params
        }
      },

      set (data) {
        this.request.body_type = data.type
        this.request.body_raw = data.body
        this.request.body_json = data.json
        this.request.body_form_params = data.formParams
      }
    }
  },

  watch: {
    selectedHttpMethod (method) {
      this.request.method = method.type
    },
    selectedRequestType (val) {
      this.request.type = val.type
      if (val.type === 'http') {
        bus.$emit('ResponseContent', 'block')
      } else {
        bus.$emit('ResponseContent', 'none')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .MonitorSettingsRequest {
    max-width: 550px;

    .toolbar {
      position: sticky;
      bottom:0;
      padding: 15px 0;
      backdrop-filter: blur(3px);

      margin-top: 20px;
    }
  }
</style>
