<template>
  <icon-question-filled ref="icon"
                        width="14"
                        height="14"
                        color="#999ca7" />
</template>

<script>
import tippy from 'tippy.js'

export default {
  mounted () {
    tippy(this.$refs.icon.$el, {
      content: 'If we do not get response from your site after this number of seconds we consider it is offline'
    })
  }
}
</script>
