<template>
  <div class="MonitorSettingsResponse">
        <base-textarea label="Response text"
                       ref="body"
                       description="We will verify that the response contains the given text"
                       rows="5"
                       v-model="response.body"
                       @input="$emit('update:response', response)"
        />

        <base-checkbox label="Case insensitive" v-model="response.body_case_insensitive"
                       @input="$emit('update:response', response)"/>

        <!--       <base-dropdown label="Expected Status Code"
                              description="In case you need to verify specific response code"
                              item-label="title"
                              track-by="number"
                              v-model="selectedExpectedStatusCode"
                              :options="statusCodes"
                              :disabled="monitorData.anySuccessfulCode"/>

               <base-checkbox label="Let it just be any of successful codes"
                              v-model="monitorData.response.any_successful_code"/>-->

        <response-headers v-model="response.headers"
                          @update="$emit('update:response', response)"/>

      <base-textbox label ="Expected statuses"
                    v-model="response.expected_code"
                    description="Add the expected response statuses. E.g.: 200,201,202"
                    @input="$emit('update:response', response)"/>

      <base-checkbox label="Not in response" v-model="response.is_negative"
                     @input="$emit('update:response', response)"/>

  </div>
</template>

<script>
import { cloneObject } from '@/services/utils.js'

import ResponseHeaders from './ResponseHeaders.vue'

export default {
  components: {
    ResponseHeaders
  },

  props: {
    monitor: {
      required: true,
      type: Object
    }
  },

  data () {
    return {
      response: {
        body: '',
        headers: [],
        is_negative: 0,
        expected_code: '',
        body_case_insensitive: 0
      }
    }
  },

  created () {
    this.response = cloneObject(this.monitor.response)
  }
}
</script>

<style lang="scss" scoped>
  .MonitorSettingsResponse {
    max-width: 550px;

    .toolbar {
      position: sticky;
      bottom:0;
      padding: 15px 0;
      backdrop-filter: blur(3px);

      margin-top: 20px;
    }
  }
</style>
