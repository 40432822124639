<template>
  <div class="SettingsLayout" v-if="monitor">
    <ValidationObserver ref="observer" v-slot="{ passes }">
      <div class="row">
        <div class="col-md-8">
          <form @submit.prevent="passes(onFormSubmit)">
            <tabs>
              <tab title="General Settings" description="URL, Name and Request Frequency">
                <div class="card">
                  <div class="card-body settings-card">
                    <monitor-settings-general
                      :key="monitor.url"
                      :monitor="monitor"
                      @update:url="formData.url = $event"
                      @update:name="formData.name = $event"
                      @update:offline_notification_delay="formData.offline_notification_delay = $event"
                      @update:request_timeout="setRequestTimeout($event)"
                      @update:uptime_check_frequency="formData.uptime_check_frequency = $event.value"
                      @update:ssl_check="formData.ssl_check = $event"
                    />
                  </div>
                </div>
              </tab>
              <tab title="Request Settings" description="Method and Request Body / Headers">
                <div class="card">
                  <div class="card-body settings-card">
                    <monitor-settings-request :monitor="monitor"
                                              @update:request_type="setRequestType($event.type)"
                                              @update:request_http_method="formData.request.method = $event.type"
                                              @update:request_body="setRequestBody($event)"
                                              @update:request_headers="formData.request.headers = $event"
                                              @update:request_tcp_ports="formData.request.tcp_ports = $event"/>
                  </div>
                </div>
              </tab>
              <tab title="Response Settings" description="Response Text,  Headers and Test"
                  :className="formData.request.type !== 'http' ? 'd-none' : ''">
                <div class="card">
                  <div class="card-body settings-card">
                    <monitor-settings-response :monitor="monitor"
                                               @update:response="setResponse($event)"/>
                  </div>
                </div>
              </tab>

            </tabs>

            <div class="toolbar">
              <base-button type="submit"
                           ref="submit"
                           class="button-update submit success"
                           :loading="saving">Update
              </base-button>

              <base-button type="button"
                           class="is-outlined"
                           ref="cancel" color="light"
                           @click="goToMonitorList">Cancel
              </base-button>
            </div>
          </form>
        </div>
        <div v-if="monitor.request.type === 'http'" id="responsePreview" class="col-md-4">
          <request-preview :monitor="monitor"/>
        </div>
      </div>
    </ValidationObserver>

    <base-alert type="error" v-if="savingError" class="mt-3">
      Something went wrong
    </base-alert>
  </div>
</template>

<script>
import monitorsApi from '@/api/monitorsApi'
import Tab from '@/views/monitors/_id/settings/tab.vue'
import Tabs from '@/views/monitors/_id/settings/tabs.vue'
import MonitorSettingsGeneral from '@/components/Monitor/MonitorSettings/MonitorSettingsGeneral.vue'
import MonitorSettingsRequest from '@/components/Monitor/MonitorSettings/MonitorSettingsRequest/Request.vue'
import MonitorSettingsResponse from '@/components/Monitor/MonitorSettings/MonitorSettingsResponse/Response.vue'
import tippy from 'tippy.js'
import RequestPreview from '@/components/Monitor/MonitorSettings/MonitorSettingsRequestPreview/RequestPreview.vue'

export default {
  components: { RequestPreview, MonitorSettingsResponse, MonitorSettingsRequest, MonitorSettingsGeneral, Tab, Tabs },
  props: {
    monitorId: {
      required: true
    }
  },

  data () {
    return {
      monitor: null,

      formData: {
        url: null,
        name: null,
        offline_notification_delay: 0,
        request: {
          timeout: null
        },
        response: {
          body: '',
          headers: [],
          is_negative: 0,
          expected_code: ''
        },
        uptime_check_frequency: null,
        ssl_check: true
      },

      updateButtonTippy: null,
      saving: false,
      savingError: false

    }
  },
  created () {
    this.loadMonitor()
  },

  methods: {
    async loadMonitor () {
      this.monitor = await monitorsApi.find(this.monitorId)
      this.formData.url = this.monitor.url
      this.formData.name = this.monitor.name
      this.formData.offline_notification_delay = this.monitor.offline_notification_delay
      this.formData.request = this.monitor.request
      this.formData.response = this.monitor.response
      this.formData.uptime_check_frequency = this.monitor.uptime_check_frequency.toString()
      this.formData.ssl_check = Boolean(this.monitor.ssl_check)
    },

    async onFormSubmit () {
      this.updateButtonTippy = tippy(this.$refs.submit.$el, {
        content: 'Settings were updated successfully',
        arrow: false,
        placement: 'right',
        trigger: 'manual'
      })
      this.savingError = false
      this.saving = true
      if (this.formData.request?.body_raw) {
        this.formData.request.body_raw = this.formData.request.body_raw.replace(/(?<!\r)\n/g, '\r\n')
      }
      if (this.formData.request.type && (this.formData.request.type === 'icmp' || this.formData.request.type === 'tcp')) {
        this.formData.request.method = null // no request method when icmp or tcp
      }
      try {
        const response = await monitorsApi.update(
          this.monitor.id,
          this.formData
        )

        this.triggerTippy()
        this.$emit('update', response)
      } catch (e) {
        this.savingError = true
      }
      this.saving = false
    },

    setRequestTimeout (val) {
      this.formData.request.timeout = val
    },

    setRequestType (val) {
      if ((this.monitor.request.type !== 'icmp' && val === 'icmp') || (this.monitor.request.type !== 'tcp' && val === 'tcp')) {
        this.formData.url = this.formData.url.replace(/(^\w+:|^)\/\//, '')
        this.monitor.url = this.formData.url.replace(/(^\w+:|^)\/\//, '')
      } else {
        if (this.formData.url.indexOf('http://') === -1 || this.formData.url.indexOf('https://') === -1) {
          this.formData.url = 'https://' + this.formData.url
          this.monitor.url = 'https://' + this.monitor.url
        }
      }
      this.formData.request.type = val
      if (val === 'http') {
        this.formData.request.method = 'get' // if the previous val was tcp/icmp, we don't have the request method because we make it null above. this will be the default value
      }
    },

    setRequestBody (val) {
      this.formData.request.body_form_params = val.formParams
      this.formData.request.body_json = val.json
      this.formData.request.body_raw = val.body
      this.formData.request.body_type = val.type
    },

    setResponse (val) {
      const clone = Object.assign({}, val)
      this.formData.response = { ...this.formData.response, ...clone }
    },

    goToMonitorList () {
      this.$router.push({
        name: 'monitors.list'
      })
    },

    triggerTippy () {
      this.updateButtonTippy.show()

      setTimeout(() => {
        this.updateButtonTippy.hide()
      }, 2000)
    }
  }
}
</script>

<style lang="scss" scoped>
.SettingsLayout {
  .tabs {
    display: flex;
    margin-bottom: 20px;

    .tab {
      display: flex;
      flex-direction: column;
      flex: 1 0;
      padding: 20px;
      background-color: #fff;
      border-radius: 6px;

      font-size: 15px;
      color: #777;
      border: 1px solid #E9EBEC;
      box-sizing: border-box;
      box-shadow: 0px 2px 4px rgba(25, 34, 29, 0.05);

      &:not(:first-child) {
        margin-left: 20px;
      }

      transition: 0.2s all;

      &:hover, &.router-link-active {
        background-color: map-get($colors, purple-1);
        cursor: pointer;
        color: #fff;
      }

      &.router-link-active {
        box-shadow: 0px 2px 4px rgba(25, 34, 29, 0.05);
      }

      .title {
        margin-bottom: 10px;
        font-size: 16px;
        font-weight: 600;
        line-height: 1;
      }

      .description {
        font-size: 14px;
        opacity: .8;
        line-height: 1.25;
      }
    }
  }

  .toolbar {
    position: sticky;
    bottom: 0;
    background: rgba(255, 255, 255, 0.9);
    padding: 15px;
    backdrop-filter: blur(3px);
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  .settings-card {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}
</style>
