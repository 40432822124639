<template>
  <div class="MonitorSettingsPreview">
    <div class="heading">This is what the request would look like</div>
    <div class="section">
      <div class="param param-url">
        <div class="name">Request URL</div>
        <div class="value">{{ monitor.url }}</div>
      </div>
      <div class="param">
        <div class="name">Method</div>
        <div class="value">{{ getRequestMethod()  | uppercase }}</div>
      </div>
      <div class="param">
        <div class="name">Remote address</div>
        <div class="value">{{ monitor.ip }}:{{httpPort}}</div>
      </div>
      <div class="param">
        <div class="name">Frequency in minutes</div>
        <div class="value">{{ monitor.uptime_check_frequency/60 }} Min</div>
      </div>
      <div class="param param-timeout">
        <div class="name">Timeout in seconds</div>
        <div class="value">
          {{ monitor.request.timeout }}
          <hint-request-timeout class="ml-1" />
        </div>
      </div>
    </div>

    <div class="section section-headers">
      <div class="section-title">Request Headers</div>
      <div class="description" v-if="!hasHeaders">
        You have not set any request headers
      </div>

      <div class="param" v-if="monitor.request.body_type === 'json'">
        <div class="name">Content-Type</div>
        <div class="value">application/json</div>
      </div>

      <div class="param" v-if="monitor.request.body_type === 'form_params'">
        <div class="name">Content-Type</div>
        <div class="value">application/x-www-form-urlencoded</div>
      </div>

      <div class="param"
           v-for="header in monitor.request.headers"
           :key="header.name">
        <div class="name">{{ header.name }}</div>
        <div class="value">{{ header.value }}</div>
      </div>
    </div>

    <div class="section">
      <request-body :monitor="monitor" />
    </div>

    <div class="section section-headers">
      <div class="section-title">Response Headers</div>
      <div class="description" v-if="!monitor.response.headers || monitor.response.headers.length === 0 ">
        You have not set any response headers
      </div>
      <div class="param"
           v-for="header in monitor.response.headers"
           :key="header.name">
        <div class="name">{{ header.name }}</div>
        <div class="value">{{ header.value }}</div>
      </div>
    </div>

    <div class="section" v-if="monitor.response.body">
      <div class="section-title">Expect Response Body Contains</div>
      <div class="description truncate">{{ monitor.response.body }}</div>
    </div>

    <base-button color="primary"
                 class="btn-run-check small"
                 :loading="checkIsRunning"
                 :outlined="true"
                 @click="runCheck">Run Check & See Result</base-button>
  </div>
</template>

<script>
import HintRequestTimeout from '@/components/Hints/HintRequestTimeout.vue'
import RequestBody from './RequestPreviewBody.vue'
import monitorsApi from '@/api/monitorsApi.js'
import Swal from 'sweetalert2'

export default {
  components: {
    HintRequestTimeout,
    RequestBody
  },

  props: {
    monitor: {
      required: true,
      type: Object
    }
  },

  data () {
    return {
      checkIsRunning: false
    }
  },

  methods: {
    async runCheck () {
      if (this.checkIsRunning) {
        return false
      }

      this.checkIsRunning = true

      const data = await monitorsApi.runCheck(this.monitor.id)
      const response = data.response

      if (data.success) {
        // Swal.fire(
        //   'Success!',
        //   `${response.code} ${response.reasonPhrase}`,
        //   'success'
        // )

        Swal.fire({
          title: 'Success',
          text: `${response.code} ${response.reasonPhrase}`,
          showClass: {
            popup: ''
          },
          hideClass: {
            popup: ''
          },
          confirmButtonColor: '#6652e0',
          allowOutsideClick: true
        })
      } else {
        Swal.fire({
          title: 'Failed',
          text: `${response.code} ${response.reasonPhrase}`,
          showClass: {
            popup: ''
          },
          hideClass: {
            popup: ''
          },
          confirmButtonColor: '#6652e0',
          allowOutsideClick: true
        })
      }

      this.checkIsRunning = false
    },

    getRequestMethod () {
      return this.monitor.request.method ?? 'get'
    }
  },

  computed: {
    hasHeaders () {
      if (this.monitor.request.body_type === 'json' || this.monitor.request.body_type === 'form_params') {
        return true
      }

      return this.monitor.request.headers && this.monitor.request.headers.length > 0
    },

    httpPort () {
      if (this.monitor.url.includes('https')) {
        return '443'
      } else {
        return '80'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .MonitorSettingsPreview {
    .heading {
      display: inline-flex;
      padding-bottom: 6px;
      margin-bottom: 20px;
      font-size: 16px;
      font-weight: 400;
      //border-bottom: 1px dotted #777;
    }

    .section {
      margin-bottom: 20px;
    }

    .section-title {
      font-weight: 400;
      margin-bottom: 4px;
    }

    .param {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding: 4px 0;

      .name {
        @include truncate;

        width: 160px;
        font-weight: 400;
        font-size: 14px;
        color: #999ca7;
      }

      .value {
        @include truncate;

        flex: 1 0;
        // font-weight: 700;
        // font-size: 11px;
        // line-height: 1;
        // text-transform: uppercase;
        font-size: 14px;
        font-weight: 500;
      }
    }

    .param-url {
      align-items: flex-start;
      flex-direction: column;
      margin-bottom: 10px;

      .name {
        margin-bottom: 6px;
      }

      .value {
        display: block;
        max-width: 100%;

        @include truncate;
      }
    }

    .param-timeout .value {
      display: flex;
      align-items: center;
    }

    .section-headers .value {
      text-transform: none;
      font-size: 12px;
    }

    .description {
      font-size: 14px;
      color: #999ca7;
    }

    // .btn-run-check {
    //   width: 200px;
    // }
  }
</style>
